import { Injectable, inject } from '@angular/core';
import { ApiService } from '@features/api';
import { Observable } from 'rxjs';
import { CallInfo, CallParticipant, CallRules, FileInfo } from '../models';

export type CustomCallType = 'organisations' | 'users' | 'teams';

@Injectable({
  providedIn: 'root',
})
export class CallsService {
  protected api = inject(ApiService);

  /**
   * Create a new emergency call.
   * @returns Call info.
   */
  call(): Observable<CallInfo> {
    return this.api.get<CallInfo>('/user/contacts/call/emergency');
  }

  /**
   * Join an existing call.
   * @param id Call ID.
   * @returns Call info.
   */
  join(id: string): Observable<CallInfo> {
    return this.api.get<CallInfo>(`/user/contacts/call/${id}/join`);
  }

  /**
   * Notify the server that the user has left the call.
   * @param id Call ID.
   * @returns
   */
  leave(id: string): Observable<void> {
    return this.api.get<void>(`/user/contacts/call/${id}/leave`);
  }

  /**
   * Reject an incoming call.
   * @param id Call ID.
   * @returns
   */
  reject(id: string): Observable<void> {
    return this.api.get<void>(`/user/contacts/call/${id}/reject`);
  }

  /**
   * Create a custom call.
   * @param config Custom call configuration.
   * @returns Call info.
   */
  custom(config: {
    custom_type: CustomCallType;
    ids: string[];
  }): Observable<CallInfo> {
    return this.api.post<CallInfo>(`/user/contacts/call/custom`, config);
  }

  /**
   * Refresh the call token.
   * This is required by the Agora SDK to keep a call alive.
   * @param id Call ID.
   * @returns Call info.
   */
  refresh(id: string) {
    return this.api.get<CallInfo>(`/user/contacts/call/${id}/refresh-token`);
  }

  /**
   * Get the call rules for the current user.
   * @returns Call rules.
   */
  rules(): Observable<CallRules> {
    return this.api.get<CallRules>('/user/teams/call-rules');
  }

  /**
   * Get the participants of a call.
   * @param id Call ID.
   * @returns Call participants.
   */
  participants(id: string): Observable<CallParticipant[]> {
    return this.api.get<CallParticipant[]>(
      `/user/contacts/call/${id}/participants`
    );
  }

  /**
   * Add participants to a call.
   * @param id Call ID
   * @param participants Participant IDs to add to the call.
   * @returns
   */
  addParticipants(
    callId: string,
    type: string,
    ids: string[]
  ): Observable<CallParticipant[]> {
    return this.api.post<CallParticipant[]>(
      `/user/contacts/call/${callId}/add-contacts`,
      { ids, custom_type: type }
    );
  }

  addFiles(id: string, files: File[]) {
    return this.api.upload<void>(`/user/contacts/call/${id}/files`, files);
  }

  files(id: string) {
    return this.api.get<FileInfo[]>(`/user/contacts/call/${id}/files`);
  }
}
